import React from 'react'
import './tmcon.css'

const Career = () => {
    return (
        <div className='career'>
            <div className="cakts">
                <h1>
                    Join Our Team
                </h1>
                <p> At Knoxo Tech, we are always on the lookout for talented individuals who are passionate about technology and innovation. Join a team of like-minded professionals and work on projects that truly make an impact. Whether you're a developer, designer, or marketing expert, there's a place for you at Knoxo.</p>
            </div>

            <div className="ava-career">

                <div className="para-aba">
                    <h1>
                        Available Roles
                    </h1>
                </div>
                <div className="ava-content">

                    <div className="daba-career">
                        <h1>
                            Web Developer </h1>
                        <p>
                            <b>Role Overview:  </b>
                             As a Web Developer, you will be responsible for designing, coding, and modifying websites, from layout to function, according to client specifications. Your role will involve creating visually appealing sites with user-friendly design and clear navigation.</p>
                    </div>

                    <div className="daba-career">
                        <h1>
                            Software Developer 
                        </h1>
                        <p>
                            <b>Role Overview: </b>
                            Our Software Developers work on designing, testing, and deploying software solutions that address client needs. You'll be involved in all phases of development, from concept to implementation, ensuring seamless functionality and scalability.
                        </p>
                    </div>
                    <div className="daba-career">
                        <h1>
                            Designer
                        </h1>
                        <p>
                            <b>Role Overview: </b>
                            Designers at Knoxo focus on creating visually compelling graphics, logos, and user interfaces. Your work will play a crucial role in building engaging user experiences that meet client and internal project goals.
                        </p>
                    </div>
                    <div className="daba-career">
                        <h1>
                            Data Analyst
                        </h1>
                        <p>
                            <b>Role Overview: </b>
                            As a Data Analyst, you will collect, analyze, and interpret data to help clients make informed decisions. Your insights will drive strategic initiatives and improve operational efficiencies.
                        </p>
                    </div>

                    <div className="daba-career">
                        <h1>
                            Marketing
                        </h1>
                        <p>
                            <b>Role Overview: </b>
                            Marketing professionals at Knoxo are responsible for promoting our products and services, as well as those of our clients. You'll develop strategies, create campaigns, and track performance to enhance our market presence.
                        </p>
                    </div>
                    <div className="daba-career">
                        <h1>
                            Social Media
                        </h1>
                        <p>
                            <b>Role Overview: </b>
                            Our Social Media team engages with online communities, creating content, managing profiles, and growing our clients' and Knoxo's social media presence. You'll be instrumental in building brand awareness and fostering customer relationships.
                        </p>
                    </div>
                    <div className="daba-career">
                        <h1>
                            Management
                        </h1>
                        <p>
                            <b>Role Overview: </b>
                            The Management team at Knoxo ensures the smooth execution of projects and business operations. As a manager, you'll oversee teams, coordinate with clients, and ensure project goals are met efficiently.
                        </p>
                    </div>
                </div>
            </div>
{/*  */}

        </div>
    )
}

export default Career