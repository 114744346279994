import React, { useState } from 'react';
import { db } from '../Firebase'; // Ensure this is the correct path to your Firebase config
import './contact.css';
import { addDoc, collection } from 'firebase/firestore';
import img from '../img/close.png';

const Contact = () => {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phNumber: '',
        message: '' // Added message field
    });

    const [error, setError] = useState(null); // To handle errors
    const [success, setSuccess] = useState(null); // To handle success messages

    const handleSub = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'allUserContactData'), {
                fName: formData.fname,
                lName: formData.lname,
                email: formData.email,
                phoneNumber: formData.phNumber,
                message: formData.message // Added message field
            });
            setSuccess('Your message has been sent successfully!');
            setFormData({
                fname: '',
                lname: '',
                email: '',
                phNumber: '',
                message: ''
            });
            setError(null);
        } catch (error) {
            setError("Error adding document: " + error.message);
            setSuccess(null);
        }
    };

    const changeOnTrgt = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <div className='contact-pg'>
            <div className="img">
                <div className="btnn" onClick={() => { window.history.back() }}>
                    <img src={img} alt="close" />
                </div>
            </div>

            <div className="fomo-form-lgsn">
                 <div className="btnnt" onClick={() => { window.history.back() }}>
                    <img src={img} alt="close" />
                </div>

                <div className="ctn-lgsn">
                    <h1>Contact Us</h1>
                </div>

                

                <form className='forms' onSubmit={handleSub}>
                    <div className="name">
                        <input
                            type="text"
                            name="fname"
                            placeholder='First Name'
                            value={formData.fname}
                            onChange={changeOnTrgt}
                            required
                        />
                        <input
                            type="text"
                            name="lname"
                            placeholder='Last Name'
                            value={formData.lname}
                            onChange={changeOnTrgt}
                            required
                        />
                    </div>

                    <div className="det">
                        <input
                            type="email"
                            name="email"
                            placeholder='Email'
                            value={formData.email}
                            onChange={changeOnTrgt}
                            required
                        />
                        <input
                            type="tel"
                            name="phNumber"
                            placeholder='Phone number'
                            value={formData.phNumber}
                            onChange={changeOnTrgt}
                            required
                        />
                    </div>
                    <div className="txt-ar">
                        <textarea
                            name="message"
                            placeholder='Write your Message'
                            value={formData.message}
                            onChange={changeOnTrgt}
                            required
                        />
                    </div>

                    <div className="btn-sub">
                        <input type="submit" value="Submit" id='btn' />
                    </div>
                </form>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
            </div>
        </div>
    );
};

export default Contact;
