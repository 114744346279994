import React from 'react'

const cancellation_refund_policy = () => {
    return (
        <div className='cancellation_refund_policy'>

            <div className="can-hd">
                <h1>Cancellation & Refund Policy</h1>
                <p>At Knoxo, we strive to provide exceptional IT services, including web development, app development, graphic design, video editing, SEO optimization, and web hosting. This Cancellation and Refund Policy outlines the conditions under which cancellations and refunds are accepted.</p>
            </div>

            <div className="al-fund-policy">
                <div className="can-fund-bx">
                    <h1>Cancellation Policy</h1>
                    <p>We understand that circumstances may change, and you may need to cancel your project or service request. Our cancellation policy is as follows:</p>
                    <ul>
                        <li><b>Before Work Begins: </b> You can cancel your order for a full refund if the cancellation is made before we begin work on the project. Please notify us as soon as possible via email or phone.</li>
                        <li><b>After Work Begins: </b>  If work has already commenced, cancellation will result in the forfeiture of the 40% upfront payment. This is to cover the time and resources spent on the project to that point. Any further payments already made for the project are non-refundable.</li>
                        <li><b>Ongoing Services (e.g., SEO, Web Hosting): </b> For ongoing services that are billed periodically (e.g., monthly, quarterly), cancellations can be made at any time. However, payments made for the current billing period are non-refundable. Services will continue until the end of the billing cycle.</li>
                    </ul>
                </div>
                <div className="can-fund-bx">
                    <h1>Refund Policy</h1>
                    <p>Refunds are offered under the following conditions:</p>
                    <ul>
                        <li><b>Partial Refund: </b> If you choose to cancel a project midway, and we have completed less than 50% of the work, you may be eligible for a partial refund at our discretion, minus the 40% non-refundable deposit.

                        </li>
                        <li><b>No Refund After Service Completion: </b> Once a project has been completed or if the work is more than halfway done, no refunds will be issued. However, we will work with you to address any issues or dissatisfaction with the service, ensuring the final product meets your expectations.

                        </li>
                        <li><b>Exceptional Circumstances: </b> In rare cases where we are unable to deliver the service as agreed upon due to internal issues or unavoidable circumstances, a full or partial refund may be provided based on the extent of work completed.

                        </li>
                    </ul>
                </div>
                <div className="can-fund-bx">
                    <h1>Revision Policy</h1>
                    <p>We offer a limited number of revisions for each service as specified in the service agreement. Additional revisions or significant changes requested after the initial agreed-upon revisions may be subject to additional charges. Refunds will not be issued in cases where the service is completed but the client requests revisions outside the original scope of the agreement.</p>
                </div>
                <div className="can-fund-bx">
                    <h1>Requesting a Refund</h1>
                    <p>To request a refund, please contact us at:</p>
                    <ul>
                        <li>Email: sales@knoxo.in</li>
                        <li>Phone: Our Provided Phone Number</li>
                    </ul>
                    <p>All refund requests will be reviewed on a case-by-case basis. If your refund is approved, the amount will be credited back to the original method of payment within [ 7-10 business days].</p>
                </div>
                <div className="can-fund-bx">
                    <h1> Contact Information</h1>
                    <p>For any questions regarding cancellations or refunds, please contact us at:</p>
                    <ul>
                        <li>Email: sales@knoxo.in</li>
                        <li>Phone: Our Provided Phone Number</li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default cancellation_refund_policy