import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./hs.css";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={isScrolled ? "scrolled" : ""}>
      <div className="logo">Knoxo</div>
      <nav className={`nav ${isMobileMenuOpen ? "nav--mobile-open" : ""}`}>
        <ul className="nav-list">
          <li className="nav-item"><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
          <li className="nav-item"><Link to="/about-us" onClick={toggleMobileMenu}>About Us</Link></li>
          <li className="nav-item"><Link to="/product" onClick={toggleMobileMenu}>Product</Link></li>
          <li className="nav-item"><Link to="/" onClick={toggleMobileMenu}>Service</Link></li>
          <li className="nav-item"><Link to="/contact" onClick={toggleMobileMenu}>Contact</Link></li>
        </ul>
      </nav>
      <button className="mobile-menu-button" onClick={toggleMobileMenu}>
        ☰
      </button>
    </header>
  );
};

export default Header;
