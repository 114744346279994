import React from 'react'
import './tmcon.css'

const Privacy_policy = () => {
    return (
        <div className='praivate-policy'>

            <div className="pri-hd">
                <h1>Privacy Policy</h1>
                <p>At <b>Knoxo</b>, we value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, store, and protect your information when you use our services, including web development, app development, graphic design, video editing, SEO optimization, and web hosting.</p>
            </div>

            <div className="all-cont-privacy">
                <div className="privacy-box">
                    <h1>Information We Collect</h1>
                    <p>We collect personal information when you fill out our contact form. The information we collect may include:</p>
                    <ul>
                        <li>Name</li>
                        <li>Email Address</li>
                        <li>Phone Number</li>
                        <li>Any other details you voluntarily provide</li>
                    </ul>
                    <b>We do not collect sensitive information such as payment details or personal identification numbers.</b>
                </div>

                <div className="privacy-box">
                    <h1>How We Use Your Information</h1>
                    <p>The data we collect is used solely to provide you with our IT services. Specifically, we use your information for:</p>
                    <ul>
                        <li>Contacting you regarding the services you requested</li>
                        <li>Customizing our service to meet your needs</li>
                        <li>Sending you updates about our services, when necessary</li>
                    </ul>
                    <b>We do not sell, rent, or share your personal information with any third-party organizations.</b>
                    
                </div>
                
                <div className="privacy-box box-privv">
                    <h1>Data Storage and Security</h1>

                    <p>Your information is stored in our secure database, and we take appropriate security measures to protect it. Access to your personal data is restricted to authorized personnel only.</p>

                    <p>We send a confirmation email to you when you fill out our contact form, and a copy of your data is sent to our team. We strive to maintain the security of this data and prevent any unauthorized access.</p>
                </div>

                <div className="privacy-box">
                    <h1>Your Data Rights</h1>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access the information we hold about you</li>
                        <li>Request correction of inaccurate or incomplete data</li>
                        <li>Request the deletion of your data (subject to applicable legal and contractual obligations)</li>
                    </ul>
                    <b>To exercise any of these rights, you can contact us at <i>support@knoxo.in</i></b>
                </div>
                <div className="privacy-box">
                    <h1>Third-Party Links</h1>

                    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these external sites. We encourage you to review their privacy policies before providing any personal data.</p>
                </div>
                <div className="privacy-box">
                    <h1> Cookies</h1>
                    <p>We may use cookies to enhance your experience on our website. Cookies help us understand how you use our site and improve functionality. You can disable cookies in your browser settings if you prefer not to allow their use.</p>
                </div>
                <div className="privacy-box">
                    <h1>Changes to This Privacy Policy</h1>
                    <p>We may update this Privacy Policy annually or as required. Any changes will be communicated to you via email. Your continued use of our services following any changes signifies your acceptance of the updated policy.</p>
                </div>
                <div className="privacy-box">
                    <h1>Contact Us</h1>
                    <p>If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at</p>
                    <ul>
                        <li>Email: support@knoxo.in</li>
                        {/* <li>Phone: [Your Contact Phone Number]</li> */}
                    </ul>
                </div>
            </div>



        </div>
    )
}

export default Privacy_policy