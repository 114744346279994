import { getAuth} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    
  apiKey: "AIzaSyARfL1EvZVF-x6LnfsNNnMBmTaSshVijhU",
  authDomain: "knoxouserdata.firebaseapp.com",
  projectId: "knoxouserdata",
  storageBucket: "knoxouserdata.appspot.com",
  messagingSenderId: "92206332921",
  appId: "1:92206332921:web:cf31a3388b43dc68fd5b28"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };
const db = getFirestore(app);

export { db, app };