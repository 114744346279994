import React from 'react';
import img1 from '../img/2232195.png';
import './style.css';
import reactjs from '../img/icons8-react-js-80.png';
import redux from '../img/redux.png';
import html from '../img/icons8-html-100.png'
import css from '../img/icons8-css-100.png'
import javascript from '../img/icons8-javascript-96.png'
import angular from '../img/icons8-angular-50.png'
import tailwindcss from '../img/icons8-tailwind-css-96.png'
import firebase from "../img/icons8-firebase-a-google's-mobile-platform-that-helps-you-quickly-develop-high-quality-apps-96.png"
import flutter from "../img/icons8-flutter-100.png"
import python from '../img/icons8-python-96.png'
import bootstrap from '../img/icons8-bootstrap-96.png'
import mongodb from '../img/icons8-mongodb-a-cross-platform-document-oriented-database-program-96.png'
import nodemonjs from '../img/icons8-nodejs-100.png'
import php from '../img/icons8-php-96.png'
import postgraphsql from '../img/icons8-postgresql-100.png'
import Contact from '../Components/Contact';


const MainPage = () => {
    return (
        <div className='mainpage'>

            <div className="cal">
                <div className="calli">
                    <h2>
                        <p>
                        3+ leading companies count on **Knoxo** for innovative development and seamless maintenance.
                        </p>
                    </h2>
                    <p>We leverage data and technology to transform you into a brand. We believe in innovation.</p>
                    <button>VIEW OUR PRODUCT</button>
                </div>
                <div className="cai">
                    <img src={img1} alt="" height={650} />
                </div>
            </div>

            <div className="whweoff">
                <div className="wiu">
                    <h1>
                        What We offer.
                        <hr />
                    </h1>
                    <p>
                        With our unique and creative problem solving methodology specially <br />
                        tailored for custom requirements of our clients, we are one of the most <br />
                        advanced, yet simplest solution providers out there.
                    </p>
                </div>
                <div className="container-whwe">
                    <div className="box-whwe">
                        <div className="box-img-whwe">
                            <i class="ti-user theme-color"></i>
                        </div>
                        <h2>Machine Learning and AI</h2>
                        <p>Knoxo's AI development services help enterprises in building amazing AI software, virtual agents or personal assistants.</p>
                    </div>

                    <div className="box-whwe">
                        <div className="box-img-whwe"></div>
                        <h2>
                            Android and IOS Dev</h2>
                        <p>We are specialised in creating and developing Android and IOS dev. software of any large and small business.</p>
                    </div>

                    <div className="box-whwe">
                        <div className="box-img-whwe"></div>
                        <h2>WEB SERVICES</h2>
                        <p>Specialising in creating highly performing site for small and large enterprise business.</p>
                    </div>

                    <div className="box-whwe">
                        <div className="box-img-whwe"></div>
                        <h2>Analytics</h2>
                        <p>We're the world's largest pure-play data analytics firm providing data science solutions.</p>
                    </div>

                    <div className="box-whwe">
                        <div className="box-img-whwe"></div>
                        <h2>UI/UX Design</h2>
                        <p>As a full-service UX design agency, we work closely with our clients to define, design, and develop transformative user experiences across all platforms and brand's touch points.</p>
                    </div>

                    <div className="box-whwe">
                        <div className="box-img-whwe"></div>
                        <h2>BOT and SEO</h2>
                        <p>Our SEO team works keeping Google algorithm in consideration to bring your page on the top. We are having highly skilled dedicated team for SEO.</p>
                    </div>
                </div>

            </div>

            <div className="new-cont">

                <div className="nw-cot">
                    <h2>
                        15
                    </h2>
                    <p>
                        HAPPY CLIENTS
                    </p>
                </div>
                <div className="nw-cot">
                    <h2>
                        15
                    </h2>
                    <p>
                        Projects Completed
                    </p>
                </div>
                <div className="nw-cot">
                    <h2>
                        5
                    </h2>
                    <p>
                        Experience
                    </p>
                </div>
                <div className="nw-cot">
                    <h2>
                        45
                    </h2>
                    <p>
                        Resources
                    </p>
                </div>

            </div>

            <div className="tech-inuse">
                <div className="hd-tech-head">
                    <h1>
                        Technologies and Framework
                        <hr />
                    </h1>
                    <p>Here are skill used in the creation of development</p>
                </div>
                <div className="cont-tech">
                    <div className="t-tech-box">
                        <h1>Frontend</h1>
                        <div className="tech-box">
                            <div className="box-btn">
                                <img src={reactjs} alt="" />
                                <p>React JS</p>
                            </div>
                            <div className="box-btn">
                                <img src={redux} alt="" />
                                <p>Redux</p>
                            </div>
                            <div className="box-btn">
                                <img src={angular} alt="" />
                                <p>Angular Js</p>
                            </div>
                            <div className="box-btn">
                                <img src={html} alt="" />
                                <p>Html 5</p>
                            </div>
                            <div className="box-btn">
                                <img src={css} alt="" />
                                <p>Css</p>
                            </div>
                            <div className="box-btn">
                                <img src={javascript} alt="" />
                                <p>JavaScript</p>
                            </div>
                            <div className="box-btn">
                                <img src={bootstrap} alt="" />
                                <p>BootStrap</p>
                            </div>

                            <div className="box-btn">
                                <img src={flutter} alt="" />
                                <p>Flutter</p>
                            </div>
                            <div className="box-btn">
                                <img src={tailwindcss} alt="" />
                                <p>Tailwind Css</p>
                            </div>


                        </div>
                    </div>
                    <div className="t-tech-box">

                        <h1>Backend</h1>
                        <div className="tech-box">
                            <div className="box-btn">
                                <img src={nodemonjs} alt="" />
                                <p>Node Js</p>
                            </div>
                            <div className="box-btn">
                                <img src={python} alt="" />
                                <p>Python</p>
                            </div>
                            <div className="box-btn">
                                <img src={php} alt="" />
                                <p>PHP</p>
                            </div>
                            <div className="box-btn">
                                <img src={mongodb} alt="" />
                                <p>MongoDb</p>
                            </div>
                            <div className="box-btn">
                                <img src={firebase} alt="" />
                                <p>Firebase</p>
                            </div>
                            <div className="box-btn">
                                <img src={postgraphsql} alt="" />
                                <p>PostgreSql</p>
                            </div>
                        </div>

                    </div>

                    {/* this part is for adding more type of tech */}
                    {/* <div className="t-tech-box">
                        <h1>Frontend</h1>
                        <div className="tech-box">
                            <div className="box-btn">
                                <img src={reactjs} alt="" />
                                <p>React JS</p>
                            </div>
                            <div className="box-btn">
                                <img src={redux} alt="" />
                                <p>Redux</p>
                            </div>
                            <div className="box-btn">
                                <img src={angular} alt="" />
                                <p>Angular Js</p>
                            </div>
                            <div className="box-btn">
                                <img src={html} alt="" />
                                <p>Html 5</p>
                            </div>
                            <div className="box-btn">
                                <img src={css} alt="" />
                                <p>Css</p>
                            </div>
                            <div className="box-btn">
                                <img src={javascript} alt="" />
                                <p>JavaScript</p>
                            </div>
                            <div className="box-btn">
                                <img src={bootstrap} alt="" />
                                <p>BootStrap</p>
                            </div>

                            <div className="box-btn">
                                <img src={flutter} alt="" />
                                <p>Flutter</p>
                            </div>
                            <div className="box-btn">
                                <img src={tailwindcss} alt="" />
                                <p>Tailwind Css</p>
                            </div>
                        </div>

                    </div> */}


                </div>
            </div>

            {/* imported part */} <Contact/>



        </div >
    )
}

export default MainPage;