import React from 'react'
import './About.css'

const About = () => {
    return (
        <div className="about-us">

            {/* starting */}

            <div className="two-faces-dyn">

                <div className="first-dyn-fc">
                    <h1>About Us</h1>
                </div>
                <div className="second-dyn-fc">
                    <div className="totalpart">
                        <div className="part1divd">
                            <h1>Who We Are</h1>
                            <p>Knoxo Tech is a forward-thinking IT company based in Hajipur, Bihar. We specialize in providing innovative, tailor-made IT solutions that address the unique challenges businesses face. Our team is dedicated to helping companies across industries grow by streamlining their operations, expanding their market reach, and introducing new offerings to their customers. Whether you're a small business or a large corporation, we provide the tools and expertise to scale efficiently and effectively.</p>
                        </div>
                        <div className="part1divd">
                            <h1>Our Mission
                            </h1>
                            <p>Our mission is to empower businesses by offering state-of-the-art IT solutions that improve efficiency, foster growth, and enable expansion into new markets. We believe in delivering solutions that don't just solve problems but help businesses thrive in the long run.
                            </p>
                        </div>
                        <div className="part1divd">
                            <h1>Our Vision</h1>
                            <p>
                                To be the go-to IT partner for businesses across the globe, known for our ability to innovate and deliver solutions that are both scalable and impactful.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* second part */}
{/* 
                <div className="st-part-abt-us">
                       <h1>Want to know more about us</h1>
                </div> */}

            {/* ending */}

        </div>
    )
}

export default About