import React from 'react'
import './tmcon.css'

const Term_Condition = () => {
    return (
        <div className="term-condition">
            <div className="je">
                <h2>
                    Terms and Conditions
                </h2>
                {/* <h2>
                    Effective Date: 1 April 2024
                </h2> */}



            </div>
            <div className="indt">
                    <span>Welcome to Knoxo</span>
                <p>
                      By accessing or using our website, you agree to comply with and be bound by the following Terms and Conditions. If you do not agree to these terms, please do not use our website.
                </p>
            </div>
            <div className="content-term">
                <div className='ctn-term'>
                    <h1>
                        Services
                    </h1>
                    <p>
                        We provide various services including website development, app development, IT consultancy, web design, UI/UX design, hosting, WordPress website development, SEO optimization, video editing, and graphic design. Users need to fill out the contact form to access our services, and we will reach out to them to provide our expertise.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>
                        User Accounts
                    </h1>
                    <p>
                        Account creation is not required to use our services. Users must fill out a contact form to engage with us.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>
                        Content Ownership
                    </h1>
                    <p>
                        We own 100% of the content on our website, including text, images, and designs. Unauthorized use or reproduction of our content is prohibited.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>
                        Data Handling and Privacy
                    </h1>
                    <p>
                        We handle user data personally. When you fill out the contact form, a mail is sent to you, and a copy of your data is sent to our team. Data is stored in a database and used solely for contacting you regarding service deals. We do not share your information and do not collect sensitive data that could be harmful if leaked.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>
                        Payment and Refund Policy
                    </h1>
                    <p>
                        A 40% upfront payment is required for our services. Refunds are not provided if the work is done or the service is provided. For any issues with our services, you may contact us via the provided contact number or email.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>
                        Dispute Resolution
                    </h1>
                    <p>
                        Disputes will be resolved through the provided contact number or email. We aim to address and resolve any issues promptly.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>

                        Governing Law
                    </h1>
                    <p>
                        These Terms and Conditions are governed by the laws of India. Any disputes will be subject to the exclusive jurisdiction of the courts in India.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>
                        Modifications to Terms
                    </h1>
                    <p>
                        We may update these Terms and Conditions annually based on surveys. Changes will be communicated to you via email. Your continued use of our website after such modifications constitutes your acceptance of the updated terms.
                    </p>
                </div>

                <div className='ctn-term'>
                    <h1>
                        Contact Information
                    </h1>
                    <p>
                        For any questions or concerns regarding these Terms and Conditions, please contact us at support@knoxo.in 
                    </p>
                </div>

            </div>


        </div>
    )
}

export default Term_Condition