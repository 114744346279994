import React from 'react'

const Delivery_policy = () => {
    return (
        <div className="delivery-policy">
            <div className="del-policu">
                <h1>Delivery Policy</h1>
                <p>At Knoxo, we are committed to delivering high-quality IT services, including web development, app development, graphic design, video editing, SEO optimization, and web hosting, in a timely and efficient manner. This Delivery Policy outlines how we manage the delivery of our services.</p>
            </div>

            <div className="policy-lines">
                <div className="line-policy">
                    <h1>Service Delivery Timeline</h1>
                    <p>We begin working on your project after receiving the required 40% upfront payment. The timeline for delivering services varies depending on the nature and scope of the project. Typical delivery times include:</p>
                    <ul>
                        <li><b>Web Development & App Development: </b> Estimated timeline is provided after project requirements are fully discussed.</li>
                        <li><b>Graphic Design & Video Editing:  </b>Delivery timelines are set based on project complexity and revisions.</li>
                        <li><b>SEO Optimization: </b> Ongoing services with agreed-upon milestones and periodic reports.</li>
                        <li><b>Web Hosting: </b> Activation of hosting services occurs within [insert time frame] after payment.</li>
                    </ul>
                    <p>You will be informed of the estimated delivery timeline during initial project discussions. We strive to meet agreed-upon deadlines but may adjust timelines based on project changes or unforeseen circumstances.</p>
                </div>
                <div className="line-policy">
                    <h1> Revisions and Adjustments</h1>
                    <p>After the delivery of a service, you will have the opportunity to review the work. We offer a limited number of revisions, which will be specified in the service agreement. Any additional revisions may incur extra costs.</p>
                    <ul>
                        <li><b>Minor Changes: </b> Free of charge within the agreed revision limit.</li>
                        <li><b>Major Changes: </b> May be subject to additional charges, depending on the scope.</li>
                    </ul>
                </div>
                <div className="line-policy">
                    <h1>Communication During Delivery</h1>
                    <p>Throughout the delivery process, we maintain regular communication with you to ensure transparency and keep you updated on progress. You can reach out to us via email or phone for updates or queries related to your project.</p>
                </div>
                <div className="line-policy">
                    <h1>Delivery Method</h1>
                    <p>For digital services, such as web development, app development, graphic design, and video editing, final deliverables will be provided through secure digital means, such as:</p>
                    <ul>
                        <li>Email</li>
                        <li>Cloud-based file sharing platforms (e.g., Google Drive, Dropbox)</li>
                        <li>Direct upload to your website or server (for web projects)</li>
                    </ul>
                    <p>For ongoing services like SEO optimization or web hosting, reports and updates will be shared periodically via email or through a dedicated dashboard, depending on the service package.</p>
                </div>
                <div className="line-policy">
                    <h1>Delays and Issues</h1>
                    <p>In the event of a delay, we will notify you immediately and provide an updated delivery timeline. Should any issues arise, we will work closely with you to resolve them as quickly as possible.</p>

                </div>
                <div className="line-policy">
                    <h1>Refund Policy</h1>
                    <p>We do not offer refunds once the work is half done or the service has been fully provided. However, if you experience any issues with the service delivered, please contact us via email or phone, and we will address the problem promptly.</p>

                </div>
                <div className="line-policy">
                    <h1>Contact Information</h1>
                    <p>For any questions or concerns regarding delivery, please contact us at:</p>
                    <ul>
                        <li>Email: sales@knoxo.in</li>
                        {/* <li>Phone: [Your Contact Phone Number]</li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Delivery_policy