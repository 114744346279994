import React from 'react';
import './foot.css'
import { Link } from 'react-router-dom';
import insta from '../img/icons8-instagram-100.png'
import linkden from '../img/icons8-linkedin-100.png'
import twitter from '../img/icons8-twitter-100 (1).png'
import facebook from '../img/icons8-f-100.png'

const Footer = () => {
  return (
    <div className='my-foot'>
      <footer>

        <div className="about-us-foot">
          <h1>About Us <hr /></h1>
          <div className="com1">
            <p><b>Registered Office: </b> HMT <br /> Colony, Kalamassery, Kochi, Kerala.</p>
          </div>

          <div className="com1">
            <p><b>Bangalore: </b> WeWork Prestige Central, 36, <br /> Infantry Road, Bangalore, Karnataka 560001</p>
          </div>

          <div className="com1">
            <p><b>Patna: </b> Opposite Gate No 93, <br /> Digha Danapur Road, Patna - 800013
            </p>
          </div>

          <div className="com2">
            <p> <b>Our Phone</b> <br />
              Office: (+91) 76448 57476</p>
          </div>

          <div className="social-media">
            <div className="iig">
              <img src={facebook} alt="" />
            </div>
            <div className="iig">
              <img src={twitter} alt="" />
            </div>
            <div className="iig">
              <img src={linkden} alt="" />
            </div>
            <div className="iig">
              <img src={insta} alt="" />
            </div>

          </div>


        </div>

        <div className="twopart">

          <div className="company">
            <h1>Company <hr /></h1>
            <ul>
              <li><Link to={'/product'}>Product</Link></li>
              <li><Link to={'/'}>Services</Link></li>
              <li><Link to={'/career'}>Careers</Link></li>
              <li><a href="https://dev.knoxo.in">Dev's Profile</a></li>
              {/* add more */}
            </ul>
          </div>

          <div className="polices">
            <h1>Policies <hr /></h1>
            <ul>
              <li><Link to={'/delivery-policy'}>Delivery Policy</Link></li>
              <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
              <li><Link to={'/term-condition'}>Terms & Conditions</Link></li>
              <li><Link to={'/cancellation-or-refund-policy'}>Cancellation & Refund Policy</Link></li>
              {/* add more  */}
            </ul>
          </div>

        </div>

        <div className="reaching">
          <h1>Reach Us <hr /></h1>
          <ul>
            <li><h2>For Business Purpose mail us at:</h2></li>
            <li><p>support@knoxo.in</p></li>
            <li><p>sales@knoxo.in</p></li>

          </ul>

        </div>

      </footer>
      <div className="copy-claims">
        <p>All © Copyright Knoxo. All Rights Reserved. 2024</p>
      </div>
    </div>
  )
}

export default Footer