import  { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { app } from '../Firebase.js';
import { v4 } from 'uuid';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

const UserData = () => {
    const database = getFirestore(app);

    const [location, setLocation] = useState(null);

    useEffect(() => {
        const existingUserId = Cookies.get('userId');
        if (existingUserId) {
            // User ID exists, update interaction time
            const existingUserData = Cookies.get('userData');
            if (existingUserData) {
                const userData = JSON.parse(existingUserData);
                const updatedUserData = {
                    ...userData,
                    userInteractionTime: new Date().toISOString(),
                };
                Cookies.set('userData', JSON.stringify(updatedUserData));
                console.log('User interaction time updated:', updatedUserData);

                // Update Firestore with the new interaction time
                const userDocRef = doc(database, 'UsersCookie', existingUserId);
                setDoc(userDocRef, { userInteractionTime: updatedUserData.userInteractionTime }, { merge: true })
                    .then(() => console.log('User interaction time updated in Firestore'))
                    .catch((error) => console.error('Error updating Firestore:', error));

                return; // Exit early if userId exists
            }
        }

        // No userId exists, create new user data
        const fetchLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setLocation({ latitude, longitude });

                        // Store location in a cookie
                        Cookies.set('userLocation', JSON.stringify({ latitude, longitude }), { expires: 7 });
                        console.log('Location saved to cookies:', { latitude, longitude });
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };

        fetchLocation();
    }, []); // Empty dependency array means this runs once on component mount

    useEffect(() => {
        const saveUserToFirestore = async (newUserData) => {
            const userDocRef = doc(database, 'UsersCookie', newUserData.userId);

            const userDocSnapshot = await getDoc(userDocRef);

            if (!userDocSnapshot.exists()) {
                // Create a new user document
                await setDoc(userDocRef, newUserData)
                    .then(() => console.log('New user added to Firestore:', newUserData))
                    .catch((error) => console.error('Error adding user to Firestore:', error));
            }
        };

        // Only set user data if location is available
        if (location) {
            const newUserData = {
                userId: v4(),
                userLocation: location,
                userFirstTimeVisitDateAndTime: new Date().toISOString(),
                userInteractionTime: new Date().toISOString(), // Initial interaction time
            };

            // Store user data in a cookie
            Cookies.set('userData', JSON.stringify(newUserData));
            Cookies.set('userId', newUserData.userId); // Store userId separately
            console.log('User data saved to cookies:', newUserData);

            // Save new user to Firestore if not already present
            saveUserToFirestore(newUserData);
        }
    }, [location]); // Runs when location state is updated

    return null; // This component doesn't render anything
};

export default UserData;
